import { icon } from "leaflet";

const menu = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    component: "Dashboard/index",
    to: "/Dashboard",
    name: "Dashboard",
    permission: "all",
  },
  {
    title: "Employee",
    icon: "mdi-account-group",
    items: [
      // {
      //   title: "Employee",
      //   to: "/Employee",
      //   component: "Employee/Employee",
      //   name: "Employee",
      //   permission: "Employee",
      //   icon: "mdi-account-group",
      // },
      {
        title: "Employee",
        to: "/employee-v2",
        component: "Employee/employee/EmployeeV2",
        name: "Employee V2",
        permission: "Employee",
        icon: "mdi-account-group",
        update: true,
      },
      {
        title: "Field Coordinator",
        to: "/FieldCoordinator",
        component: "Employee/FieldCoordinator/index",
        name: "FieldCoordinator",
        permission: "FieldCoordinator",
        icon: "mdi-account",
      },
      // {
      //   title: "Field Facilitator",
      //   to: "/FieldFacilitator",
      //   component: "Employee/FF.old",
      //   name: "FieldFacilitator",
      //   permission: "FieldFacilitator",
      //   icon: "mdi-account",
      // },
      {
        title: "Field Facilitator",
        to: "/field-facilitator-v2",
        component: "Employee/FF",
        name: "field-facilitator-v2",
        permission: "FieldFacilitator",
        icon: "mdi-account",
        update: true,
      },
      {
        title: "Employee Menu",
        to: "/EmployeeMenu",
        component: "Employee/EmployeeMenu",
        name: "EmployeeMenu",
        permission: "EmployeeMenu",
        icon: "mdi-account-details",
      },
      {
        title: "Employee Manager",
        to: "/EmployeeManager",
        component: "Employee/EmployeeManager",
        name: "EmployeeManager",
        permission: "EmployeeManager",
        icon: "mdi-account-tie",
      },
      {
        title: "Employee Position",
        to: "/EmployeePosition",
        component: "Employee/EmployeePosition",
        name: "EmployeePosition",
        permission: "EmployeePosition",
        icon: "mdi-account-star",
      },
    ],
  },
  {
    title: "Main Data",
    icon: "mdi-database",
    items: [
      // {
      //   title: "Farmer",
      //   to: "/Farmer",
      //   component: "Farmer/Farmer.old",
      //   name: "Farmer",
      //   permission: "Farmer",
      // },
      {
        title: "Farmer",
        to: "/farmer-v2",
        component: "Farmer/Farmer",
        name: "Farmer",
        permission: "Farmer",
        prevent_route_validation: true,
        icon: "mdi-account-cowboy-hat",
        update: true,
      },
      // {
      //   title: "Lahan",
      //   to: "/Lahan",
      //   component: "Lahan/Lahan.old",
      //   name: "LahanV1",
      //   permission: "Lahan",
      //   icon: "mdi-square-rounded",
      // },
      {
        title: "Lahan",
        to: "/lahan-v2",
        component: "Lahan/Lahan",
        name: "Lahan",
        permission: "Lahan",
        icon: "mdi-square-rounded",
        update: true,
        prevent_route_validation: true,
      },
      {
        title: "Lahan",
        to: "/lahan-2",
        component: "Lahan/Lahan2",
        name: "Lahan2",
        permission: "Lahan2",
        icon: "mdi-square-rounded",
        update: true,
        prevent_route_validation: true,
        third: true,
      },
      // {
      //   title: "Lahan Umum",
      //   to: "/LahanUmum",
      //   component: "Lahan/LahanUmum",
      //   name: "LahanUmum",
      //   permission: "LahanUmum",
      //   icon: "mdi-square-rounded-badge",
      // },
      
      {
        title: "Donor",
        to: "/Donor",
        component: "Donor/Donor",
        name: "Donor",
        permission: "Donor",
        icon: "mdi-account-heart",
      },
      {
        title: "Project",
        to: "/Project",
        component: "Project/Project",
        name: "Project",
        permission: "Project",
        icon: "mdi-projector-screen",
      },
      {
        title: "Truck & Driver",
        to: "/TruckDriver",
        component: "TruckDriver/TruckDriver",
        name: "TruckDriver",
        permission: "TruckDriver",
        icon: "mdi-truck-check",
      },
    ],
  },
  // {
  //   title: "New Activities",
  //   icon: "mdi-lightbulb-auto-outline",
  //   items:   [
  //     {
  //       title: "Scooping Visit",
  //       to: "/scooping-visit-v2",
  //       component: "ActivityNew/scooping_visit/ScoopingVisit",
  //       name: "newScoopingVisit",
  //       permission: "scooping-visit-v2",
  //       icon: "mdi-home-edit",
  //     },
  //     {
  //       title: "RRA & PRA",
  //       to: "/rra-pra-v2",
  //       component: "ActivityNew/rra_pra/RraPra",
  //       name: "newRraPra",
  //       permission: "rra-pra-v2",
  //       icon: "mdi-home-search",
  //     },
  //     {
  //       title: "Sosialisasi Program",
  //       to: "/sosialisasi-program-v2",
  //       component: "ActivityNew/program_soc/ProgramSoc",
  //       name: "newSosialisasiProgram",
  //       permission: "sosialisasi-program-v2",
  //       icon: "mdi-account-card",
  //     },
  //     {
  //       title: "Petani (Sosialisasi Program)",
  //       to: "/sosialisasi-program-farmer-v2",
  //       component: "ActivityNew/program_soc/ProgramSocFarmer",
  //       name: "newSosialisasiProgram",
  //       permission: "sosialisasi-program-v2",
  //       prevent_route_validation: true,
  //       hide: true,
  //     },
  //   ],
  // },
  {
    title: "Lahan Umum / Event",
    icon: "mdi-square-rounded-badge",
    items:[
      {
        title: "Lahan Umum / Event",
        to: "/LahanUmumV2",
        component: "LahanUmum/LahanUmum",
        name: "LahanUmumV2",
        permission: "LahanUmum",
        icon: "mdi-square-rounded-badge",
        update: true,
      },
      {
        title: "Lahan Umum / Event - Penilikan Lubang",
        to: "/PenilikanLubangLahanUmumV2",
        component: "LahanUmum/LahanUmumPlantingHole/LahanUmumPlantingHole",
        name: "LahanUmumPlantingHoleV2",
        permission: "LubangTanam",
        icon: "mdi-check-underline-circle",
        update: true,
      },
      {
        title: "Lahan Umum / Event - Unload & Report BAST",
        to: "/UloadLahanUmumV2",
        component: "LahanUmum/LahanUmumDistributionLoadingLine/LahanUmumDistributionLoadingLine",
        name: "LahanUmumDistributionLoadingLineV2",
        permission: "DistributionUnload",
        icon: "mdi-truck-delivery",
        update: true,
      },
    ]
  },
  {
    title: "Activities",
    icon: "mdi-calendar-check",
    items: [
      {
        title: "Progression",
        to: "/Progression",
        component: "Activity/Progression",
        name: "Progression",
        permission: "Progression",
        icon: "mdi-book-check",
        type: ["non-carbon"],
      },
      {
        title: "Scooping Visit",
        to: "/scooping-visit-v2",
        component: "ActivityNew/scooping_visit/ScoopingVisit",
        name: "newScoopingVisit",
        permission: "scooping-visit-v2",
        icon: "mdi-home-edit",
        update: true,
        type: ["non-carbon"],
      },
      {
        title: "RRA & PRA",
        to: "/rra-pra-v2",
        component: "ActivityNew/rra_pra/RraPra",
        name: "newRraPra",
        permission: "rra-pra-v2",
        icon: "mdi-home-search",
        update: true,
        type: ["non-carbon"],
      },
      {
        title: "Sosialisasi Program",
        to: "/sosialisasi-program-v2",
        component: "ActivityNew/program_soc/ProgramSoc",
        name: "newSosialisasiProgram",
        permission: "sosialisasi-program-v2",
        icon: "mdi-account-card",
        update: true,
        type: ["non-carbon"],
      },
      {
        title: "Petani (Sosialisasi Program)",
        to: "/sosialisasi-program-farmer-v2",
        component: "ActivityNew/program_soc/ProgramSocFarmer",
        name: "newSosialisasiProgramFarmer",
        permission: "sosialisasi-program-v2",
        prevent_route_validation: true,
        hide: true,
      },
      // {
      //   title: "Sosialisasi Tanam",
      //   to: "/SosialisasiTanam",
      //   component: "Activity/SosialisasiTanam",
      //   name: "SosialisasiTanam",
      //   permission: "SosialisasiTanam",
      //   icon: "mdi-account-badge",
      //   type: ["non-carbon"],
      // },
      {
        title: "Sosialisasi Tanam",
        to: "/sosialisasi-tanam-v2",
        component: "ActivityNew/planting_soc/PlantingSoc",
        name: "SosialisasiTanam2",
        permission: "SosialisasiTanam",
        icon: "mdi-account-badge",
        type: ["non-carbon", "carbon"],
        update: true,
      },
      {
        title: "AdjustmentDataSostam",
        to: "/AdjustmentDataSostam",
        component: "Activity/components/sostam/AdjustmentDataSostam",
        name: "AdjustmentDataSostam",
        permission: "SosialisasiTanam",
        hide: true,
      },
      {
        title: "populateDataMonitoring1",
        to: "/populateDataMonitoring1",
        component: "Activity/monitoring2AddIn/populateDataMonitoring1",
        name: "populateDataMonitoring1",
        permission: ["Monitoring2", "PenilikanTanam"],
        hide: true,
      },
      // {
      //   title: "Pelatihan Petani old",
      //   to: "/PelatihanPetani.old",
      //   component: "Activity/PelatihanPetani",
      //   name: "PelatihanPetani1",
      //   permission: "PelatihanPetani",
      //   icon: "mdi-handshake",
      //   type: ["non-carbon"],
      // },
      // {
      //   title: "Pelatihan Petani",
      //   to: "/PelatihanPetani",
      //   component: "Activity/PelatihanPetani",
      //   name: "PelatihanPetani1",
      //   permission: "PelatihanPetani",
      //   icon: "mdi-handshake",
      //   type: ["non-carbon"],
      // },
      // {
      //   title: "Pelatihan Petani",
      //   to: "/PelatihanPetani",
      //   component: "Activity/PelatihanPetani",
      //   name: "PelatihanPetani1",
      //   permission: "PelatihanPetani",
      //   icon: "mdi-handshake",
      //   type: ["non-carbon"],
      // },
      {
        title: "Pelatihan Petani",
        to: "/PelatihanPetaniV2",
        component: "ActivityNew/pelatihan_petani/PelatihanPetani",
        name: "PelatihanPetani",
        update: true,
        permission: "PelatihanPetani",
        icon: "mdi-handshake",
        type: ["non-carbon"],
      },
      {
        title: "Lubang Tanam",
        to: "/LubangTanam",
        component: "Activity/LubangTanam",
        name: "LubangTanam1",
        permission: "LubangTanam",
        icon: "mdi-check-underline-circle",
        type: ["non-carbon"],
      },
      {
        title: "Lubang Tanam",
        to: "/LubangTanamV2",
        component: "ActivityNew/planting_hole/PlantingHole",
        name: "LubangTanam",
        update: true,
        permission: "LubangTanam",
        icon: "mdi-check-underline-circle",
        type: ["non-carbon"],
      },
      {
        title: "Material Organik",
        to: "/MaterialOrganik",
        component: "Activity/MaterialOrganik",
        name: "MaterialOrganik",
        permission: "MaterialOrganik",
        icon: "mdi-leaf-circle",
        type: ["non-carbon"],
      },
      // {
      //   title: "SeedlingChangeRequest",
      //   to: "/SeedlingChangeRequest",
      //   component: "Activity/components/distribusi/SeedlingChangeRequest/index",
      //   name: "SeedlingChangeRequest",
      //   permission: "Distribusi",
      //   hide: true,
      // },
      // {
      //   title: "Distribusi",
      //   to: "/Distribusi",
      //   component: "Activity/Distribusi",
      //   name: "Distribusi",
      //   permission: "Distribusi",
      //   icon: "mdi-truck-fast",
      //   type: ["non-carbon"],
      // },
      // {
      //   title: "Realisasi Tanam",
      //   to: "/PenilikanTanam",
      //   component: "Activity/PenilikanTanam",
      //   name: "PenilikanTanam",
      //   permission: "PenilikanTanam",
      //   icon: "mdi-spa",
      //   type: ["non-carbon"],
      // },
      {
        title: "Realisasi Tanam",
        to: "/PenilikanTanamV2",
        component: "ActivityNew/realisasi_tanam/PenilikanTanam",
        name: "PenilikanTanamV2",
        permission: "PenilikanTanam",
        icon: "mdi-spa",
        type: ["non-carbon"],
        update: true,
      },
      {
        title: "Monitoring",
        to: "/Monitoring2",
        component: "Activity/Monitoring2",
        name: "Monitoring2",
        permission: "Monitoring2",
        icon: "mdi-tree",
        type: ["non-carbon"],
      },
      {
        title: "PermintaanTutupanLahan",
        to: "/PermintaanTutupanLahan",
        component: "Lahan/tutupanLahan",
        name: "PermintaanTutupanLahan",
        permission: "Lahan",
        hide: true,
      },
      {
        title: "DaftarQRLahanRusak",
        to: "/DaftarQRLahanRusak",
        component: "Lahan/QrLahanRusak",
        name: "DaftarQRLahanRusak",
        permission: "Lahan",
        hide: true,
      },
      {
        title: "Lihat Tanggal Distribusi",
        to: "/LihatTanggalDistribusi",
        component: "Activity/components/distribusi/viewDistribution",
        name: "LihatTanggalDistribusi",
        permission: "Distribusi",
        hide: true,
      },

      //CARBON
      {
        title: "Social Engagement",
        to: "/social-engagement",
        component: "ActivityNew/social_engagement/SocialEngagement",
        name: "SocialEngagement",
        permission: "SosialisasiProgram",
        icon: "mdi-account-supervisor",
        type: ["carbon"],
      },
    ],
  },
  {
    title: "Distribusi",
    icon: "mdi-truck-delivery",
    items:[
      {
        title: "Distribusi - Kalender",
        to: "/Distribusi",
        component: "Distribution/calendar_distribution/CalendarDistribution",
        name: "Distribusi",
        update: true,
        permission: "Distribusi",
        icon: "mdi-calendar-multiselect",
        type: ["non-carbon"],
      },
      {
        title: "Distribusi - Unload & Report BAST",
        to: "/Distribusi/Unload",
        component: "Distribution/UnloadDistribution/Unload",
        name: "Distribution Unload",
        update: true,
        permission: "DistributionUnload",
        icon: "mdi-truck-delivery",
        type: ["non-carbon"],
      },
      {
        title: "Pengiriman Ulang",
        to: "/distribution/reallocation",
        component: "ActivityNew/reallocation/Reallocation",
        name: "Pengiriman Ulang",
        update: true,
        permission: "Reallocation",
        icon: "mdi-truck-delivery",
        type: ["non-carbon"],
      },
    ]
  },
  {
    title: "Monitoring",
    icon: "mdi-truck-delivery",
    items:[
      {
        title: "Monitoring 2",
        to: "/Monitoring2_new",
        component: "Monitoring/Monitoring2",
        name: "crud-monitoring2",
        update: true,
        permission: "Monitoring",
        icon: "mdi-calendar-multiselect",
        type: ["non-carbon"],
      }
    ]
  },

  {
    title: "Utilities",
    icon: "mdi-cogs",
    items: [
      {
        title: "Provinsi",
        to: "/Provinsi",
        component: "Utilities/provinsi/ProvinsiV2",
        name: "Provinsi",
        update: true,
        permission: "Provinsi",
        icon: "mdi-map-marker",
      },
      {
        title: "Kab/Kota",
        to: "/Kabupaten",
        component: "Utilities/kabupaten/KabupatenV2",
        update: true,
        name: "Kabupaten",
        permission: "Kabupaten",
        icon: "mdi-map-marker",
      },
      {
        title: "Kecamatan",
        to: "/Kecamatan",
        component: "Utilities/kecamatan/KecamatanV2",
        update: true,
        name: "Kecamatan",
        permission: "Kecamatan",
        icon: "mdi-map-marker",
      },
      // {
      //   title: "Old Village",
      //   to: "/test2",
      //   component: "Utilities/vilage/Vilage.old",
      //   name: "Test2",
      //   permission: "Test2",
      //   icon: "mdi-map-marker",
      // },
      
      // {
      //   title: "Village",
      //   to: "/village",
      //   component: "Utilities/vilage/Vilage.old",
      //   name: "Village",
      //   permission: "Village",
      //   icon: "mdi-map-marker",
      // },
      {
        title: "Village",
        to: "/Vilage-v2",
        component: "Utilities/vilage/VilageV2",
        name: "Vilage",
        update: true,
        permission: "Vilage",
        icon: "mdi-map-marker",
        update: true,
      },
      // {
      //   title: "Desa",
      //   to: "/village-v2",
      //   component: "Utilities/Village",
      //   name: "Village",
      //   permission: "Vilage",
      //   icon: "mdi-map-marker",
      // },
      {
        title: "Regional",
        to: "/Regional",
        component: "Utilities/Regional",
        name: "Regional",
        permission: "Regional",
        icon: "mdi-map-marker-radius",
      },
      {
        title: "Management Unit",
        to: "/ManagementUnit",
        component: "Utilities/MU",
        name: "ManagementUnit",
        permission: "ManagementUnit",
        icon: "mdi-map-marker-radius",
      },
      {
        title: "Target Area",
        to: "/TargetArea",
        component: "Utilities/TA",
        name: "TargetArea",
        permission: "TargetArea",
        icon: "mdi-map-marker-radius",
      },
      {
        title: "Pekerjaan",
        to: "/Pekerjaan",
        component: "Utilities/Pekerjaan",
        name: "Pekerjaan",
        permission: "Pekerjaan",
        icon: "mdi-bag-personal",
      },
      {
        title: "Suku",
        to: "/Suku",
        component: "Utilities/Suku",
        name: "Suku",
        permission: "Suku",
        icon: "mdi-account-multiple",
      },
      {
        title: "Trees",
        to: "/Trees",
        component: "Trees/Trees",
        name: "Trees",
        permission: "Trees",
        icon: "mdi-tree",
      },
      {
        title: "Tree Model",
        to: "/tree-model",
        component: "Trees/TreeModel",
        name: "TreeModel",
        permission: "Trees",
        icon: "mdi-tree",
        update: true,
      },
    ],
  },

  {
    title: "Master Data",
    icon: "mdi-database-cog-outline",
    items: [
      {
        title: "Area Management System",
        to: "/AreaManagementSystem",
        component: "AreasManagement/main-ams/mainListAMS",
        name: "Area Management System",
        permission: "AreaManagementSystem",
        icon: "mdi-application-array",
      },
      {
        title: "Tipe Project",
        to: "/ProjectTypes",
        component: "Utilities/ProjectTypes",
        name: "ProjectTypes",
        permission: "ProjectTypes",
        icon: "mdi-database-edit",
      },
      {
        title: "Tujuan Penanaman",
        to: "/ProjectPlantingPurpose",
        component: "Utilities/ProjectPlantingPurpose",
        name: "ProjectPlantingPurpose",
        permission: "ProjectPlantingPurpose",
        icon: "mdi-database-edit",
      },
      {
        title: "Media Tanam",
        to: "/ProjectPlantingEnvironment",
        component: "Utilities/ProjectPlantingEnvironment",
        name: "ProjectPlantingEnvironment",
        permission: "ProjectPlantingEnvironment",
        icon: "mdi-database-edit",
      },
      {
        title: "Task",
        to: "/task",
        component: "MasterData/Tasks",
        name: "Task",
        permission: "Task",
        icon: "mdi-database-edit",
      },
      {
        title: "Task Group",
        to: "/task-group",
        component: "MasterData/TaskGroups",
        name: "TaskGroup",
        permission: "TaskGroup",
        icon: "mdi-database-edit",
      },
      {
        title: "Roles",
        to: "/roles",
        component: "MasterData/Roles",
        name: "Roles",
        permission: "TaskGroup",
        icon: "mdi-database-edit",
      },
      // {
      //   title: "Area Management System",
      //   to: "/ams",
      //   component: "AreasManagement/main-ams/mainListAMS",
      //   name: "Area Management System",
      //   permission: "AreaManagementSystem",
      //   icon: "mdi-application-array",
      // },
    ],
  },
  {
    title: "Users",
    icon: "mdi-account",
    items: [
      {
        title: "Users",
        to: "/Users",
        component: "Users/Users",
        name: "Users",
        permission: "Users",
        icon: "mdi-account",
      },
    ],
  },

  {
    title: "Report Data",
    icon: "mdi-table-arrow-right",
    component: "Report/index",
    to: "/report-data",
    name: "ReportData",
    permission: "ReportData",
  },
];

export default menu;
